import React, { FC } from 'react';
import { makeStyles } from '@mui/styles'; 
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';


const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        marginTop: '40px',
        marginBottom: '40px'
    },
    connect: {
        marginTop: '12px',
    },
    logo: {
        width: '70px'
    },
    itemFlexGrow: {
        flexGrow: 1,
    },
}));

export const Header: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <div className={classes.logo}> <img src={require('../public/logo.png')} /> </div>
            <div className={classes.itemFlexGrow}>  </div>
            <div className={classes.connect}> <WalletMultiButton /> </div>
        </div>
    )
};