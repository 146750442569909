import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Theme } from './Theme';
import { Dashboard } from './Dashboard';
import { VestingCreator } from './VestingCreator';
import { VestingUnlocker } from './VestingUnlocker';
import { VestingUpdate } from './VestingUpdate';


export const App: FC = () => {
    return (
        <Theme>
            <Context>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="create" element={<VestingCreator />} />
                        <Route path="unlock" element={<VestingUnlocker />} />
                        <Route path="update" element={<VestingUpdate />} />
                    </Routes>
                </BrowserRouter>
            </Context>
        </Theme>
    );
};

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;
    // const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    //  GenesysGo rpc  //  "https://ssc-dao.genesysgo.net"; 
    //  Serum rpc  //  "https://solana-api.projectserum.com"; 
    const endpoint = "https://stylish-little-hexagon.solana-mainnet.quiknode.pro/2a8c25f8530901da6bad63ed651b6003143ece2b"; // useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    const { enqueueSnackbar } = useSnackbar();
    const onError = useCallback(
        (error: WalletError) => {
            enqueueSnackbar(error.message ? `${error.name}: ${error.message}` : error.name, { variant: 'error' });
            console.error(error);
        },
        [enqueueSnackbar]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} onError={onError} autoConnect>
                <WalletDialogProvider>{children}</WalletDialogProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
