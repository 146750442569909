import { WalletNotConnectedError, WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet, WalletContextState, ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider, WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import { Transaction, PublicKey, Connection, ParsedAccountData, AccountInfo } from '@solana/web3.js';
import { Token, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback } from 'react';
import { makeStyles } from '@mui/styles'; 



const useStyles = makeStyles(() => ({
    container: {
        width: "90%",
    },
    header: {
        display: "flex",
        marginTop: '40px',
        marginBottom: '40px'
    },
    connect: {
        marginTop: '12px',
    },
    logo: {
        width: '70px'
    },
    itemFlexGrow: {
        flexGrow: 1,
    },
    content: {
        textAlign: 'center',
        minHeight: '80vh',
    },
    infoContainer: {
        marginTop: '40px',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    infoBox: {
        border: '2px solid gray',
        borderRadius: 10,
        margin: '20px',
        padding: '20px'
    },
    buttonsBox: {
        margin: '10px',
        padding: '10px'
    },
    buyButton: {
        width: '140px',
        margin: '10px',
        height: '36px'
    }
}));

// test address   "7WjaDdUvq57hGeiLcefczf3UMzMvDBwdbpgptXuuypSe"; 
const MINT_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";     // USDC
const SERENITY_ADDRESS = "4V1XdfpkC63EvXPWPtNaF8RqWoFLiaUi2G1nFo5m8Sf9";  // TODO!!!!


const getOrCreateAssociatedTokenAccount = async (connection: Connection, sendTransaction: any, mint: PublicKey, publicKey: PublicKey, feePayer: PublicKey): Promise<[PublicKey, AccountInfo<Buffer | ParsedAccountData> | null]>  => {
    const associatedAddress = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        publicKey,
    );

    console.log('publicKey: ', publicKey.toString());
    console.log('associatedAddress: ', associatedAddress.toString());

    let accountAccountInfo = await connection.getParsedAccountInfo(associatedAddress);

    if (!accountAccountInfo.value) {
        // Create ATA
        let tx = new Transaction().add(
            Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
                TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
                mint, // mint
                associatedAddress, // ata
                publicKey, // owner of token account
                feePayer // fee payer
            )
        );

        const signature = await sendTransaction(tx, connection);
        await connection.confirmTransaction(signature, 'processed');

        accountAccountInfo = await connection.getParsedAccountInfo(associatedAddress);
    }

    return [associatedAddress, accountAccountInfo.value];
}


export const Dashboard: FC = () => {
    const { connection } = useConnection();
    const { publicKey, wallet, sendTransaction } = useWallet();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    console.log(publicKey?.toString());

    var usdtMintAddress = new PublicKey(MINT_ADDRESS);
    
    const buyTicket = useCallback(async (event) => {
        const ticket = event.currentTarget.dataset.ticket;
        const amount = ticket * 1_000_000 * 1_000;

        if (!publicKey || !wallet) throw new WalletNotConnectedError();

        const [fromAddress, fromAtaInfo] = await getOrCreateAssociatedTokenAccount(connection, sendTransaction, usdtMintAddress, publicKey, publicKey);

        console.log(fromAtaInfo?.data);

        const serenityAddress = new PublicKey(SERENITY_ADDRESS);
        const [toAddress, toAtaInfo] = await getOrCreateAssociatedTokenAccount(connection, sendTransaction, usdtMintAddress, serenityAddress, publicKey);

        console.log(toAtaInfo?.data);

        // Add token transfer instructions to transaction
        var transaction = new Transaction()
            .add(
                Token.createTransferInstruction(
                    TOKEN_PROGRAM_ID,
                    fromAddress,
                    toAddress,
                    publicKey,
                    [],
                    amount
                )
            );

        const signature = await sendTransaction(transaction, connection);
        const txConfirmation = await connection.confirmTransaction(signature, 'processed');

        if (!txConfirmation.value.err) {
            enqueueSnackbar('Payment successful', { 
                variant: 'success',
            });
        }

        console.log('Success!!');

    }, [publicKey]);



    return (

        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.logo}> <img src={require('../public/logo.png')} /> </div>
                <div className={classes.itemFlexGrow}>  </div>
                <div className={classes.connect}> <WalletMultiButton /> </div>
            </div>
            
            <div className={classes.content}>
                <h1> Investors Dashboard </h1>

                {wallet ? (
                <div className={classes.infoContainer}>
                    <h2>Initial Ticket Sale</h2>
                    <div className={classes.infoBox}>
                        <p>Buying a ticket, you will transfer the value </p>
                        <p>of the ticket in USDC to the Serenity Account.</p>
                        <p>Then you should inform the action to let</p>
                        <p>Serenity team create the vesting contract.</p>
                    </div>
                </div>
                ) : (
                <div className={classes.infoContainer}>
                    <br/><br/><br/><br/>
                    <h3>Connect wallet to proceed</h3>
                </div>
                )}

                {wallet && (
                <div className={classes.infoContainer}>
                    <h2>Buy ticket</h2>
                    <div className={classes.buttonsBox}>
                        <button className={classes.buyButton} data-ticket={25} onClick={buyTicket}>Peace $25k</button>
                        <button className={classes.buyButton} data-ticket={50} onClick={buyTicket}>Calm $50k</button><br/>
                        <button className={classes.buyButton} data-ticket={75} onClick={buyTicket}>Tranquil $75k</button>
                        <button className={classes.buyButton} data-ticket={20} onClick={buyTicket}> Free $20k</button>
                    </div>
                </div>
                )}
            </div>

        </div>

    );
};


