import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Transaction, PublicKey } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles'; 
import { Grid } from '@mui/material';
import BN from "bn.js";

import GraphSection from "./Graph";
import { getContractKeyFromSeed, timeConverter, SERENITY_DECIMALS, SERENITY_MINT_ADDRESS } from './utils';
import { TOKEN_VESTING_PROGRAM_ID, getContractInfo, unlock, ContractInfo } from '@bonfida/token-vesting';
import { Header } from './Header';


const useStyles = makeStyles((theme) => ({
    container: {
        width: "90%",
    },
    content: {
        textAlign: 'center',
        minHeight: '80vh',
    },
    infoContainer: {
        marginTop: '40px',
    },
    infoBox: {
        border: '2px solid gray',
        borderRadius: 10,
        margin: '20px',
        padding: '20px',
        backgroundColor: 'rgba(3, 3, 3, 0.7)'
    },
    infoLabel: {
        margin: '0px auto',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    buttonsBox: {
        margin: '10px',
        padding: '10px'
    },
    createButton: {
        width: '180px',
        margin: '10px',
        height: '36px'
    },
    inputAddress: {
        width: '100%'
    },
    graphContainer: {
        height: "max(30vh, 200px)",
        margin: "auto",
        fontSize: "14px",
        padding: "15px",
        marginBottom: "10px"
    },
    destinationContainer: {
        margin: "10px",
        [theme.breakpoints.down('md')]: {
            fontSize: "0.6em"
        }
    },
    destinationLink: {
        color: "white"
    }
}));


export const VestingUnlocker: FC = () => {
    const { connection } = useConnection();
    const { publicKey, wallet, sendTransaction } = useWallet();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [contractSeed, setContractSeed] = useState('');
    const [contractInfo, setContractInfo] = useState<ContractInfo | null>(null);

    console.log('Public key: ', publicKey?.toString());

    const readVestingInfo = async () => {
        if (!publicKey || !wallet) throw new WalletNotConnectedError();

        const vestingAccountKey = await getContractKeyFromSeed(
            Buffer.from(contractSeed, "hex")
        );
        const vestInfo = await getContractInfo(connection, vestingAccountKey);

        console.log('Contract Seed: ', vestingAccountKey.toString());
        console.log('Destination Address: ', vestInfo.destinationAddress.toString());
        console.log('Mint Address: ', vestInfo.mintAddress.toString());

        setContractInfo(vestInfo);
    };

    const unlockTokens = async () => {
        if (!publicKey || !wallet) throw new WalletNotConnectedError();

        console.log('Seed: ', contractSeed);

        const tx = await unlock(connection, 
            TOKEN_VESTING_PROGRAM_ID, Buffer.from(contractSeed, 'hex'), SERENITY_MINT_ADDRESS );

        var transaction = new Transaction().add(tx[0]);

        const signature = await sendTransaction(transaction, connection);
        const txConfirmation = await connection.confirmTransaction(signature, 'processed');

        if (!txConfirmation.value.err) {
            enqueueSnackbar('Payment successful', { 
                variant: 'success',
            });
        }
    };

    return (
        <div className={classes.container}>
            <Header/>
            
            <div className={classes.content}>
                <h1> Vesting Contract Creation Tool </h1>

                {wallet ? (
                <Grid container className={classes.infoContainer} direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12} md={8} >
                        <span className={classes.infoLabel}>Vesting Contract Info</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} className={classes.infoBox}>
                        <label> Contract Seed
                            <input type={'text'} 
                                value={contractSeed}  name="contractSeed" className={classes.inputAddress} 
                                onChange={(e) => setContractSeed(e.target.value)}  /> <br/>
                        </label>
                        <button className={classes.createButton} onClick={readVestingInfo}>Read Info</button>
                        <button className={classes.createButton} onClick={unlockTokens}>Unlock Tokens</button>
                        { contractInfo && ( 
                        <div>
                            <div className={classes.graphContainer}> 
                                <GraphSection
                                    data={contractInfo?.schedules.map((s) => {
                                        const numerateur = new BN(s.amount.toString());
                                        const denominateur = new BN(Math.pow(10, SERENITY_DECIMALS).toString());
                                        return {
                                        amount: numerateur.div(denominateur).toNumber(),
                                        time: timeConverter(new Date(s.releaseTime.toNumber() * 1000)),
                                        };
                                    })}
                                    xKey="time"
                                    yKey="amount"
                                />
                            </div>
                            <div className={classes.destinationContainer}>
                                <a className={classes.destinationLink} target="_blank" href={`https://explorer.solana.com/address/${contractInfo?.destinationAddress.toString()}`}>
                                Destination address: {contractInfo?.destinationAddress.toString()}</a>
                            </div>
                        </div>
                        )}
                    </Grid>
                </Grid>

                ) : (
                <div className={classes.infoContainer}>
                    <br/><br/><br/><br/>
                    <h3>Connect wallet to proceed</h3>
                </div>
                )}

            </div>
        </div>
    );
};



