import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Connection, Transaction, PublicKey } from '@solana/web3.js';
import { Token, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

import { getContractKeyFromSeed } from './utils';
import { TOKEN_VESTING_PROGRAM_ID, getContractInfo, changeDestination } from '@bonfida/token-vesting';
import { Header } from './Header';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '90%',
    },
    content: {
        textAlign: 'center',
        minHeight: '80vh',
    },
    infoContainer: {
        marginTop: '40px',
    },
    infoBox: {
        border: '2px solid gray',
        borderRadius: 10,
        margin: '20px',
        padding: '20px',
        backgroundColor: 'rgba(3, 3, 3, 0.7)',
    },
    infoLabel: {
        margin: '0px auto',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    buttonsBox: {
        margin: '10px',
        padding: '10px',
    },
    createButton: {
        width: '180px',
        margin: '10px',
        height: '36px',
    },
    inputAddress: {
        width: '100%',
        margin: '8px',
    },
    graphContainer: {
        height: 'max(30vh, 200px)',
        margin: 'auto',
        fontSize: '14px',
        padding: '15px',
        marginBottom: '10px',
    },
    destinationContainer: {
        margin: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.6em',
        },
    },
    destinationLink: {
        color: 'white',
    },
}));

const getOrCreateAssociatedTokenAccount = async (
    connection: Connection,
    sendTransaction: any,
    mint: PublicKey,
    publicKey: PublicKey,
    feePayer: PublicKey
): Promise<[PublicKey, AccountInfo<Buffer | ParsedAccountData> | null]> => {
    const associatedAddress = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        publicKey
    );

    console.log('publicKey: ', publicKey.toString());
    console.log('associatedAddress: ', associatedAddress.toString());

    let accountAccountInfo = await connection.getParsedAccountInfo(associatedAddress);

    if (!accountAccountInfo.value) {
        console.log('Creating ata');

        // Create ATA
        let tx = new Transaction().add(
            Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                mint,
                associatedAddress,
                publicKey,
                feePayer
            )
        );

        const signature = await sendTransaction(tx, connection);
        await connection.confirmTransaction(signature, 'processed');

        accountAccountInfo = await connection.getParsedAccountInfo(associatedAddress);
    }

    return [associatedAddress, accountAccountInfo.value];
};

export const VestingUpdate: FC = () => {
    const { connection } = useConnection();
    const { publicKey, wallet, sendTransaction } = useWallet();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [contractSeed, setContractSeed] = useState('');
    const [destinationAddress, setDestinationAddress] = useState('');

    console.log('Public key: ', publicKey?.toString());

    const changeDestinationHandler = async () => {
        if (!publicKey || !wallet) throw new WalletNotConnectedError();

        const vestingAccountKey = await getContractKeyFromSeed(Buffer.from(contractSeed, 'hex'));
        const vestInfo = await getContractInfo(connection, vestingAccountKey);
        const destinationPubkey = new PublicKey(destinationAddress);

        console.log('Seed: ', contractSeed);

        const tx = await changeDestination(
            connection,
            TOKEN_VESTING_PROGRAM_ID,
            publicKey,
            destinationPubkey,
            undefined,
            [Buffer.from(contractSeed, 'hex')]
        );

        var transaction = new Transaction().add(tx[0]);

        const signature = await sendTransaction(transaction, connection);
        const txConfirmation = await connection.confirmTransaction(signature, 'processed');

        if (!txConfirmation.value.err) {
            enqueueSnackbar('Payment successful', {
                variant: 'success',
            });
        }
    };

    return (
        <div className={classes.container}>
            <Header />

            <div className={classes.content}>
                <h1> Vesting Contract Creation Tool </h1>

                {wallet ? (
                    <Grid
                        container
                        className={classes.infoContainer}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={8}>
                            <span className={classes.infoLabel}>Update Destination Contract Info</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} className={classes.infoBox}>
                            <label>
                                {' '}
                                Contract Seed
                                <input
                                    type={'text'}
                                    value={contractSeed}
                                    name="contractSeed"
                                    className={classes.inputAddress}
                                    onChange={(e) => setContractSeed(e.target.value)}
                                />{' '}
                                <br />
                            </label>
                            <label>
                                {' '}
                                New Destination Address
                                <input
                                    type={'text'}
                                    value={destinationAddress}
                                    name="destinationAddress"
                                    className={classes.inputAddress}
                                    onChange={(e) => setDestinationAddress(e.target.value)}
                                />{' '}
                                <br />
                            </label>
                            <button className={classes.createButton} onClick={changeDestinationHandler}>
                                Update
                            </button>
                        </Grid>
                    </Grid>
                ) : (
                    <div className={classes.infoContainer}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3>Connect wallet to proceed</h3>
                    </div>
                )}
            </div>
        </div>
    );
};
