import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Transaction, PublicKey, Connection, ParsedAccountData, AccountInfo } from '@solana/web3.js';
import { Token, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles'; 

import { Schedule, 
    generateRandomSeed, 
    Numberu64, 
    create, 
    TOKEN_VESTING_PROGRAM_ID
} from '@bonfida/token-vesting';
import { INVESTOR_ADDRESS, SERENITY_DECIMALS, SERENITY_MINT_ADDRESS, SERENITY_TOKEN_ACCOUNT } from './utils';
import { Header } from './Header';


const useStyles = makeStyles(() => ({
    container: {
        width: "90%",
    },
    content: {
        textAlign: 'center',
        minHeight: '80vh',
    },
    infoContainer: {
        marginTop: '40px',
        width: '65%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    infoBox: {
        border: '2px solid gray',
        borderRadius: 10,
        margin: '20px',
        padding: '20px',
        backgroundColor: 'rgba(3, 3, 3, 0.7)'
    },
    buttonsBox: {
        margin: '10px',
        padding: '10px'
    },

    createButton: {
        width: '180px',
        margin: '10px',
        height: '36px'
    },
    inputAddress: {
        width: '450px'
    }
}));



// const schedule = [
//     {date: new Date(2022, 1, 4), amount: 500},
// ];

// // SINGLE definitive schedule
// const schedule = [
//     {date: new Date(2022, 8, 30), amount: 10000},
// ];

// // INVESTOR SEED definitive schedule
// const schedule = [
//     {date: new Date(2022, 9, 1), amount: 100000},
//     {date: new Date(2022, 10, 1), amount: 80000},
//     {date: new Date(2022, 11, 1), amount: 80000},
//     {date: new Date(2023, 0, 1), amount: 80000},
//     {date: new Date(2023, 1, 1), amount: 80000},
//     {date: new Date(2023, 2, 1), amount: 80000},
// ];

// // ADVISOR SEED definitive schedule
// const schedule = [
//     {date: new Date(2022, 7, 1), amount: 40000},
//     {date: new Date(2022, 8, 1), amount: 32000},
//     {date: new Date(2022, 9, 1), amount: 32000},
//     {date: new Date(2022, 10, 1), amount: 32000},
//     {date: new Date(2022, 11, 1), amount: 32000},
//     {date: new Date(2023, 0, 1), amount: 32000},
// ];

// CUSTOM schedule
const schedule = [
    {date: new Date(2023, 1, 25), amount: 18000},
    {date: new Date(2023, 2, 25), amount: 18000},
    {date: new Date(2023, 3, 25), amount: 18000},
    {date: new Date(2023, 4, 25), amount: 18000},
    {date: new Date(2023, 5, 25), amount: 18000},
];


const getOrCreateAssociatedTokenAccount = async (connection: Connection, sendTransaction: any, mint: PublicKey, publicKey: PublicKey, feePayer: PublicKey): Promise<[PublicKey, AccountInfo<Buffer | ParsedAccountData> | null]>  => {
    const associatedAddress = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        publicKey,
    );

    console.log('publicKey: ', publicKey.toString());
    console.log('associatedAddress: ', associatedAddress.toString());

    let accountAccountInfo = await connection.getParsedAccountInfo(associatedAddress);

    if (!accountAccountInfo.value) {
        // Create ATA
        let tx = new Transaction().add(
            Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID, 
                TOKEN_PROGRAM_ID, 
                mint, 
                associatedAddress, 
                publicKey, 
                feePayer 
            )
        );

        const signature = await sendTransaction(tx, connection);
        await connection.confirmTransaction(signature, 'processed');

        accountAccountInfo = await connection.getParsedAccountInfo(associatedAddress);
    }

    return [associatedAddress, accountAccountInfo.value];
}

/** Do some checks before sending the tokens */
const checks = async (connection: Connection, investorTokenAccount: PublicKey) => {
    const tokenInfo = await connection.getParsedAccountInfo(
        investorTokenAccount,
    );

    // @ts-ignore
    const parsed = tokenInfo.value.data.parsed;
    if (parsed.info.mint !== SERENITY_MINT_ADDRESS.toBase58()) {
        throw new Error('Invalid mint');
    }
    if (parsed.info.owner !== INVESTOR_ADDRESS.toBase58()) {
        throw new Error('Invalid owner');
    }
    if (parsed.info.tokenAmount.decimals !== SERENITY_DECIMALS) {
        throw new Error('Invalid decimals');
    }
};


export const VestingCreator: FC = () => {
    const { connection } = useConnection();
    const { publicKey, wallet, sendTransaction } = useWallet();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [contractSeed, setContractSeed] = useState('');

    console.log('Public key: ', publicKey?.toString());

    const createContract = useCallback(async (event) => {
        if (!publicKey || !wallet) throw new WalletNotConnectedError();

        const [investorTokenAccount] = await getOrCreateAssociatedTokenAccount(
            connection, sendTransaction, SERENITY_MINT_ADDRESS, INVESTOR_ADDRESS, publicKey);
        
        console.log('Investor Token Account: ', investorTokenAccount.toString());
        
        await checks(connection, investorTokenAccount);
        const schedules: Schedule[] = [];
        for (let sche of schedule) {
            schedules.push(
                new Schedule(
                    /** Has to be in seconds */
                    new Numberu64(sche.date.getTime() / 1_000),
                    /** Don't forget to add decimals */
                    new Numberu64(sche.amount * Math.pow(10, SERENITY_DECIMALS)),
                    ),
                );
        }
        const seed = generateRandomSeed();
    
        console.log(`Seed: ${seed}`);
    
        const instructions = await create(
            connection,
            TOKEN_VESTING_PROGRAM_ID,
            Buffer.from(seed, 'hex'),
            publicKey,
            publicKey,
            SERENITY_TOKEN_ACCOUNT,
            investorTokenAccount,
            SERENITY_MINT_ADDRESS,
            schedules,
        );

        // Add token transfer instructions to transaction
        var transaction = new Transaction().add(...instructions);

        const signature = await sendTransaction(transaction, connection);
        const txConfirmation = await connection.confirmTransaction(signature, 'processed');

        if (!txConfirmation.value.err) {
            enqueueSnackbar('Creation successful', { 
                variant: 'success',
            });
        }

        setContractSeed(seed);

        console.log('Success!!');

    }, [publicKey]);


    return (
        <div className={classes.container}>
            <Header/>

            <div className={classes.content}>
                <h1> Vesting Contract Creation Tool </h1>

                {wallet ? (
                <div className={classes.infoContainer}>
                    {!contractSeed ? (
                        <div >
                            <h3>NEW Vesting Contract Details</h3>
                            <div className={classes.infoBox}>
                                <p>Destination address</p><p>{INVESTOR_ADDRESS.toString()}</p><br/>
                                <p>Schedule:</p>
                                    { schedule.map((elem) => 
                                        <p>{elem.date.toLocaleDateString()} - {elem.amount}</p>    
                                    )}
                                <br/>

                                <button className={classes.createButton} onClick={createContract}>Create Contract</button>
                            </div>
                        </div>
                    ) : (<div >
                            <h3>Vesting Contract CREATED</h3>
                            <div className={classes.infoBox}>
                                <p>Copy the CONTRACT SEED in a safe place.</p>
                                <p>Only the destination address will receive the vested tokens.</p>
                                <p>It can be shared.</p><br/>
                                
                                <b>Contract Seed</b>
                                <h3>{ contractSeed }</h3>
                            </div>
                        </div>
                    ) }
                </div>
                
                ) : (
                <div className={classes.infoContainer}>
                    <br/><br/><br/><br/>
                    <h3>Connect wallet to proceed</h3>
                </div>
                )}

            </div>
        </div>
    );
};


