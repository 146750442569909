
import {
  PublicKey,
} from '@solana/web3.js';
import { TOKEN_VESTING_PROGRAM_ID } from "@bonfida/token-vesting";


// DEVNET DATA
// const SERENITY_DECIMALS = 2;
// const SERENITY_MINT_ADDRESS =  new PublicKey("7WjaDdUvq57hGeiLcefczf3UMzMvDBwdbpgptXuuypSe");  // devnet address
// const SERENITY_TOKEN_ACCOUNT = new PublicKey("3FTBqawSC7F5Mb2u3gGogb7R9HDfFVgdXFTrguq7FhE3"); // devnet mia

// const INVESTOR_ADDRESS = new PublicKey("37iL3JTRS8fZSUWu23KDV4YcAJHE7HKmAMccqjknfhB4");  // devnet mia


export const SERENITY_DECIMALS = 9;
export const SERENITY_MINT_ADDRESS =  new PublicKey("9pkudFiG5qhiSQQ8XatPbxZj8zBQ5m4XzHsKHMAGkkCR"); 
export const SERENITY_TOKEN_ACCOUNT = new PublicKey("BUy75MPVQapnYLihKEnvHHhtXqXWG3DuaWTjVVoXbcRD"); 

export const INVESTOR_ADDRESS = new PublicKey("6nMei1BSyCdyP39EwjmU1fzo7xfpqA7JMHxzdnPbnLem");    

export const getContractKeyFromSeed = async (seed: Buffer | Uint8Array) => {
  const seedWord = seed.slice(0, 31);
  const [vestingAccountKey] = await PublicKey.findProgramAddress(
    [seedWord],
    TOKEN_VESTING_PROGRAM_ID
  );
  return vestingAccountKey;
};


export const timeConverter = (date: Date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${day}/${month}/${year}`;
};